<template>
  <div class="setting-box">
    <nav-bar :title="$t('我的收藏')" :isFixed="true" :goBack="goBack" :bgc="'background: #ffffff'" />
    <div class="box-main" v-if="collectList.length>0">
      <goodItems v-for="(item,index) in collectList" :key="index" :objData="item" @Remove="handeRemoveAmount"
        @Add="handeAddAmount" @getIsAmount="handlegetIsAmount"  @onDelete="handleonDelete" />
        <van-loading v-if="isMore" size="13px" class="loading" vertical>{{ $t("加载中") }}...</van-loading>
        <div v-else class="more">{{ $t("沒有更多啦") }}~</div>
    </div>
    <div v-else class="empty">
      <img src="@/assets/image/emptyCart.png">
      <div class="title">{{ $t('暫無收藏') }}</div>
      <!-- <div class="txt">{{ $t('快去挑選自己喜歡的商品吧') }}~</div>
      <div class="empty-btn" @click="handlegoHome">{{ $t('去逛逛') }}</div> -->
    </div>
    <promptDialog ref="promptDialogRef" :confirmText="confirmText" :content="content" @success="handleSuccess" />
  </div>
</template> 
<script>
import { getUserGoodsCollection,selCollectProduct } from "@/api/user"
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
import goodItems from "./components/good-item.vue"
import promptDialog from "@/components/promptDialog/index.vue"
export default {
  name:"collectList",
  mixins: [mixins],
  components: {
    promptDialog,
    goodItems
  },
  data(){
    return{
      confirmText: this.$t('刪除'),
      confirmText1: this.$t('去認證'),
      content: this.$t('確認刪除？'),
      lang: "English",
      langEnv: 2,
      params:{
        pageIndex: 1,
        pageSize: 20
      },
      collectList:[],
      isMore: false,
      totalPage: null,
      searchProductId:null,
      erpProductNo:null
    }
  },
  created(){
    this.langEnv = window.localStorage.getItem("langEnv") || 2
    this.lang =
      window.localStorage.getItem("langEnv") == 2 ? "English" : "繁體中文"
    this.init()
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll,true)
  },
  methods:{
    async handleScroll (event) {
      const windowHeight = window.innerHeight
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight

      if (hasVerticalScrollbar && scrollTop + windowHeight >= documentHeight) {
        this.isMore = true
        this.params.pageIndex += 1
        await this.getData()
      }
    },
    async init () {
      this.params.pageIndex = 1
      await this.getData()
    },
    async getData () {
      const res = await getUserGoodsCollection(this.params)
      if (res.resultID === 1200) {
        if (this.params.pageIndex > 1) {
          if (res.data?.list.length === 0) {
            this.isMore = false
            window.removeEventListener('scroll', this.handleScroll, true)
          } else {
            this.collectList = [...this.collectList, ...res.data?.list]
          }
        } else {
          this.collectList = res.data.list
        }
      }

    },
    async handleSuccess () {
      const res = await selCollectProduct({isCollected:false,erpProductNo:this.erpProductNo,searchProductId:this.searchProductId}) 
      if (res.resultID === 1200) {
          this.$toast(this.$t('操作成功'))
          this.init()
          this.searchProductId=null
          this.erpProductNo=null
      }
    },
    async handleonDelete (data) {
      this.searchProductId= data.searchProductId
      this.erpProductNo= data.erpProductNo
      this.$refs.promptDialogRef.open()
     
    },
    handeRemoveAmount (searchProductId) {
      this.collectList.forEach(item => {
        if (item.searchProductId === searchProductId) {
          item.sellPartCount -= 1
        }
      })
    },
    handeAddAmount (searchProductId) {
      this.collectList.forEach(item => {
        if (item.searchProductId === searchProductId) {
          item.sellPartCount += 1
        }
      })
    },
    handlegetIsAmount (data) {
      this.collectList.forEach(item => {
        if (item.searchProductId === data.searchProductId) {
          item.sellPartCount = item.sellPartCount
        }
      })

    },
    goBack () {
      this.$router.back()
    }
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
}
</script>
<style lang="scss" scoped>
.setting-box{
  padding-top:44px;
  box-sizing: border-box;
  min-height: 100vh;
  .box-main{
    margin:16px 0px 24px;
    box-sizing: border-box;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
  }
}
.more {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-bottom: 10px;
}

.loading {
  text-align: center;
  padding-bottom: 10px;
}
.empty {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  font-size: 0;

  img {
    width: 120px;
    height: 120px;
    margin-bottom: 4px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4px;
  }

  .txt {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
  }

  .empty-btn {
    width: 175px;
    height: 36px;
    background: #90D12E;
    border-radius: 8px 8px 8px 8px;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
  }
}
</style>