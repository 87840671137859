<template>
  <div class="goods">
    <div class="goods-info">
      <div class="goods-img" @click="goproductDetails(goods)">
        <img :src="`${getProductImageUrl(goods.picturePath,400)}`" alt="" @error="handleImageError" />
      </div>
    </div>
    <div class="goods-info-text" @click.stop="goproductDetails(goods)">
      <div class="title">{{ goods.name }}</div>
      <div class="specifications">{{ goods.standard }}</div>
      <div class="priceAndnum">
        <div class="price">{{ formatNumber(goods.newPrice) }}</div>
        <div class="right">
          <div class="reduce">
            <img v-if=" 1 < goods.sellPartCount" @click.stop="handeRemoveAmount(goods)"
              src="@/assets/icons/remove.png" alt="" />
            <img v-else @click.stop="handeRemoveTigs()" src="@/assets/icons/forbidden.png" alt="" />
          </div>
          <div style="line-height: 1">
            <input class="input-number" @click.stop v-model.number="goods.sellPartCount" @input="getIsAmount(goods)" type="text" />
          </div>
          <div class="increase">
            <img  @click.stop="handeAddAmount(goods)"
              src="@/assets/icons/add.png" alt="" />
            <!-- <img v-else @click.stop="handeAddTigs()" src="@/assets/icons/addforbidden.png" alt="" /> -->
          </div>
        </div>
      </div>
      <div class="originalCost" v-if="goods.unitPrice < goods.originalPrice">{{ formatNumber(goods.originalPrice) }}
      </div>
      <div class="operate">
        <van-icon name="delete" class="delete" @click.stop="handleDelete" />
       <div class="join" @click.stop="handleAddToCart">
        <img  class="add-img" src="@/assets/image/general.png" alt="" srcset="">
       </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixins from "@/utils/mixins"
import { addShoppingCart } from "@/api/shopCart.js"
export default {
  name: "good-items",
  mixins: [Mixins],
  props: {
    objData: {
      type: Object,
      default: {}
    },
  },
  watch: {
    objData: {
      handler (val) {
        this.goods = val
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      goods: {},
    }
  },
  methods: {
    async handleAddToCart(){
      const goods = []
      if (this.goods.sellPartCount > 0) {
          goods.push({
            count: this.goods.sellPartCount,
            searchProductId: this.goods.searchProductId
          })
        }
      if (goods.length) {
        const res = await addShoppingCart({
          isDeleteStockRegistration: false,
          goods
        })
        if (res.resultID === 1200) {
          this.$toast(this.$t('加購成功'))
          this.$store.dispatch("shopCart/getCartCount")
        }
      }
    },
    // 跳转详情
    goproductDetails (data) {
      this.$router.push(`/product/${data.searchProductId}`)

    },
    handeRemoveAmount () {
      if (this.goods.sellPartCount <= 1) {
        return
      }
      this.$emit('Remove', this.goods.searchProductId)
    },
    handeAddAmount () {
      this.$emit('Add', this.goods.searchProductId)
    },
    handeAddTigs () {
      this.$toast(this.$t('庫存不足'))
    },
    handeRemoveTigs () {

    },
    getIsAmount (item, type) {
      if (item.sellPartCount < 1) {
        item.sellPartCount = 1
      }
      this.$emit('getIsAmount', item)
    },
    handleDelete () {
      this.$emit('onDelete', {erpProductNo:this.goods.erpProductNo,searchProductId:this.goods.searchProductId})
    },
  }
}
</script>
<style lang="scss" scoped>

img {
  width: 100%;
  height: 100%;
}

.goods {
  padding: 16px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  margin-bottom:12px;
  .goods-info {
    display: flex;
    align-items: center;

    .goods-img {
      width: 120px;
      height: 120px;
      background: #F1F1F1;
      overflow: hidden;
      border-radius: 4px;
      position: relative;
    }
  }

  .goods-info-text {
    margin-left: 16px;
    flex: 1;

    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 13px;
      color: #1A1A1A;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      margin-bottom: 4px;
    }


    .specifications {
      
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }

}

.priceAndnum {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .price {
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 30px;
  }

  .right {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    width: auto;
    border-radius: 4px 4px 4px 4px;
    background:#F8F8F8;
    .reduce {
      width:28px;
      height: 24px;
      background:#ffffff;
      display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    }
    .increase{
      width:28px;
      height: 24px;
      background:#ffffff;
      display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 0px;
    }
    img {
      width: 20px;
    }

    .input-number {
      width: 30px;
      height: 24px;
      text-align: center;
      background: #f8f8f8;
      margin: 0 8px;
      border-radius: 2px 2px 2px 2px;
    }
  }
}

.originalCost {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: left;
  text-decoration: line-through;
}
.operate{
  margin-top:10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .delete {
    font-size: 16px;
    color: #999999;
  }
  .join{
    width: 24px;
    height: 24px;
    background: #90D12E;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-img{
      width: 16px;
      height: 16px;
    }
  }
  
}
</style>